<template>
  <div class="demo-alignment">
    <vs-popup
      class="w-60"
      title="Update cover image"
      :active.sync="coverProfile"
    >
      <div class="">
        <div class="vx-row">
          <div class="vx-col w-full">
              <div>
                <div class="mb-4">
                  <input class="block" type="file" @change="upload($event)" />
                </div>
              </div>
              <clipper-basic 
                :init-width="40" 
                :min-width="40" 
                :src="imgURL" 
                ref="clipper" 
                cross-origin="cors"
                @load="imgLoad"
                >
                <div class="placeholder" slot="placeholder">Upload New Cover Image</div>
              </clipper-basic>
              <vs-button @click="submit" class="mr-3 mb-2 mt-4">Update</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { clipperBasic, clipperPreview, clipperUpload } from 'vuejs-clipper'
import awsExport from '@/aws-exports'
import { v4 as uuidv4 } from 'uuid'
import { API, graphqlOperation } from '@aws-amplify/api'
import { updateUser } from '@/graphql/mutations'
import axios from 'axios'

import S3 from 'aws-s3'
const config  = {
  bucketName: awsExport.aws_user_files_s3_bucket,
  dirName: 'cover',
  region: awsExport.aws_user_files_s3_bucket_region,
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY
}

const s3ClientCoverDir = new S3(config)

const dataURItoBlob = (dataURI, fileType) => {
  const binary = atob(dataURI.split(',')[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], {type: fileType})
}

export default {
  name: 'CoverImgUpdatePopup',
  components: {
    clipperBasic,
    clipperPreview,
    clipperUpload
  },
  data () {
    return {
      coverProfile: false,
      imgURL: '',
      fileName: '',
      fileType: ''
    }
  },
  methods: {
    submit () {
      this.$vs.loading()
      const result = this.getResult()
      s3ClientCoverDir.uploadFile(result, `cover-${uuidv4()}`)
        .then(res => {
          // const coverPhotoURL = getCompressImageUrl(res.key, 400 * 16 / 9, 400, 'fill')
          // update cover url
          API.graphql(graphqlOperation(updateUser, {
            input: {
              name: this.$store.state.auth.userData.name,
              coverPhotoURL: res.location
            } 
          })).then(updateUserRes => {
            // this.imgURL = updateUserRes.data.updateUser.coverPhotoURL
            this.$store.commit('auth/UPDATE_USER_INFO', updateUserRes.data.updateUser)
            // update this profile image into crm   
            axios.post(`${process.env.VUE_APP_BASE_URL}/api/updateProfileCoverPicUrlByEmail`, {
              email: this.$store.state.auth.userData.email,
              profile_cover_pic: res.location
            })
            this.coverProfile = false
            this.imgURL = res.location
            this.$emit('onFinishUpload', res.location)
            this.$vs.loading.close()
          }).catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    },
    upload (e) {
      if (e.target.files.length !== 0) {
        if (this.imgURL) URL.revokeObjectURL(this.imgURL)
        this.fileName = e.target.files[0].name
        this.fileType = e.target.files[0].type
        this.imgURL = window.URL.createObjectURL(e.target.files[0])
      }
    },
    getResult () {
      const canvas = this.$refs.clipper.clip()
      const dataUrl = canvas.toDataURL(this.fileType, 1)
      const blobData = dataURItoBlob(dataUrl, this.fileType)
      return new File([blobData], this.fileName, {
        type: this.fileType,
        lastModified: Date.now()
      })
    },
    imgLoad () {
      this.$refs.clipper.setTL$.next({left: 20, top: 20})
      this.$refs.clipper.setWH$.next({width: 50, height: 50})
    }
  },
  created () {
    this.imgURL = this.$store.state.auth.userData.coverPhotoURL
  }
}
</script>

<style lang="css">
.placeholder {
  text-align: center;
  padding: 20px;
  background-color: lightgray;
}

.result {
  height: auto;
}
</style>