<template>
  <div>
    <vs-button size="small" v-if="isFollowed" @click="unFollowUser">Unfollow</vs-button>
    <vs-button size="small" v-else @click="followUser">Follow</vs-button>
  </div>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api'
import { getFollow } from '@/graphql/queries'

export default {
  name: 'ProfileFollowButton',
  props:{
    email:{
      type:String,
    required:true
    },
    notify:{
      type:Boolean
    },
  
    
  },
  data () {
    return {
      isFollowed: false
    }
  },
  methods: {
    async checkFollow () {
      const res = await API.graphql(graphqlOperation(getFollow, {
        user: this.$route.params.user_name,
        follower: this.$store.state.auth.userData.name
      }))
      if (res.data.getFollow !== null) {
        this.isFollowed = true
      } else {
        this.isFollowed = false
      } 
    },
    followUser () {
      this.$store.dispatch('follow/followUser', this.$route.params.user_name).then(() => {
        this.isFollowed = true
      })
     
      if(this.notify !== false){
       this.$store.dispatch('newsfeed/sendMail', {
                user_name: this.$store.state.auth.userData.name,
                activity_user:  this.$route.params.user_name,
                activity: 'FOLLOW',
                template_code: 'send_notification',
                activity_user_mail: this.email,
                post_content: null,
                activity_user_image_url: this.$store.state.auth.userData.photoURL,
                post_image_url: null,
                profile_url: `https://mypals.app/profile/${this.$store.state.auth.userData.name}`,
                post_url: null
            })
      }
    },
    unFollowUser () {
      this.$store.dispatch('follow/unfollowCurrentAuthor', this.$route.params.user_name).then(() => {
        this.isFollowed = false
      })
    }
  },
  created () {
    this.checkFollow()
    console.log(this.$store.state.auth.userData)
  }
}
</script>