<template>
<div class="partner-educator-posts">
    <Loading v-if="isLoading" />

    <template v-if="posts && posts.length !== 0">
        <vx-card class="mb-base mb-mobile" v-for="(feed, feedIndex) in posts" :key="feedIndex" @click="viewCurrentPost(feed)">

            <!-- POST HEADER -->
            <div class="post-header flex justify-between mb-4" >
                <div class="flex items-center">
                    <div>
                     <avatar style="margin-left:5px;" v-if="feed.authorRef.photoURL.split('/')[feed.authorRef.photoURL.split('/').length-1] === 'default_dp.png'" class="border-2 border-solid border-white mr-2 my-1" :username="feed.authorRef.displayName" :size="45" />
                     <vs-avatar class="m-0" :src="feed.authorRef.photoURL" size="45px" v-else></vs-avatar>
                    </div>
                    <div class="ml-4">
                        <h5 class="mb-0 font-semibold post-header-display-name"> {{feed.authorRef.displayName}} </h5>
                        <span>{{feed.authorRef.studyStatus}}</span>

                    </div>
                </div>

            </div>

            <!-- POST CONTENT -->
 
            <div class="post-content">
                <p> {{feed.content}} </p>
            </div>

            <div class="gallery">
      <img
        v-for="(mediaFile, index) in feed.mediaFiles"
        :key="index"
        class="gallery-item responsive rounded cursor-pointer image-orientation"
        :src="mediaFile"
        @click="viewCurrentPost(feed)"
        alt="user-upload"
      />
    </div>

            <!--   <div class="post-video-container" v-if='feed.video!=null'>
                <video v-if="feed.videoURL" :key="feed.videoURL" controls class="w-full" controlsList="nodownload">
                    <source :src="feed.videoURL" />
                </video>
            </div> -->

            <div class="post-video-container" v-if='feed.video !=null'>
                <video controls class="w-full" controlsList="nodownload">
                    <source :src="feed.videoURL" />
                </video>
            </div>

            <div v-if="feed.ogMetaData && feed.ogMetaData.items && feed.ogMetaData.items.length !== 0" @click="goToWebsite(feed.ogMetaData.items[0].requestUrl)">
                <vs-card class="cursor-pointer">
                    <div class="static">
                        <div>
                            <!-- <div slot="media" v-if="ogMetaData.ogVideoUrl && ogMetaData.ogVideoType ">
                  <iframe class="border-none w-full og-video-player" :src="ogMetaData.ogVideoUrl" />
                </div> -->
                            <div slot="media" v-if="feed.ogMetaData.items[0].ogImageUrl">
                                <img :src="feed.ogMetaData.items[0].ogImageUrl" class="og-img" />
                            </div>
                            <div>
                                <h5 class="mt-4 mb-2 font-medium">{{ feed.ogMetaData.items[0].ogTitle | truncate(50) }}</h5>
                                <span v-if="feed.ogMetaData.items[0].ogSiteName">{{ feed.ogMetaData.items[0].ogSiteName }}</span>
                                <p class="text-base whitespace-pre-wrap">{{ feed.ogMetaData.items[0].ogDescription }}</p>
                            </div>
                        </div>
                    </div>
                </vs-card>
            </div>
            <div class="flex justify-between">
                <div class="flex items-center">
                    <div class="flex items-center">
                        <feather-icon class="mr-2" icon="HeartIcon" svgClasses="h-5 w-5"></feather-icon>
                        <span>{{feed.likes.items.length}} Likes</span>
                    </div>
                </div>
                <div class="flex items-center">
                    <feather-icon class="mr-2" icon="MessageSquareIcon" svgClasses="h-5 w-5"></feather-icon>
                    <span>{{feed.comments.items.length}} Comment </span>
                </div>
            </div>

        </vx-card>
    </template>
    <div class="text-center" v-if="posts && posts.length == 0">No Posts to show yet</div>

    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="flex justify-center mt-base">
                <div id="button-load-more-posts" class="vs-con-loading__container"></div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    API,
    graphqlOperation
} from '@aws-amplify/api'
import {
    partnerByUser
} from '@/graphql/queries'
import {
    postsByAuthor
} from '@/graphql/postsByAuthor'
import {
    getSharePost
} from '@/graphql/getSharePost'
import {
    getCompressImageUrl
} from '@/utils'
import Post from '@/layouts/components/coursepal/Post/Post'
import Loading from '@/layouts/components/coursepal/Loading'
import { Storage } from "@aws-amplify/storage";
import Avatar from 'vue-avatar'
export default {
    data() {
        return {
            posts: [],
            isLoading: false
        }
    },
    components: {
        Post,
        Loading,
        Avatar
    },
    computed: {
        isLoading() {
            return this.$store.state.newsfeed.isLoading
        }
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);

        this.getPost();

    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    }, 
    methods: {
        viewCurrentPost(post) {
            
            this.$router.push(`/post?postId=${post.id}`)
        },
        handleScroll() {
            if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
                this.getPost();
            }
        },
        goToWebsite(url) {
            window.open(url, '__blank')
        },
        async getPost() {
            try {

                this.isLoading = true
                const res = await API.graphql(graphqlOperation(postsByAuthor, {
                    //  author:this.$store.state.auth.userData.name,
                    author: this.$route.params.user_name,
                    filter: {
                        isDeleted: {
                            eq: false
                        }
                    },
                    sortDirection: 'DESC'
                }))

                for (const feed of res.data.postsByAuthor.items) {
                    feed.mediaFiles = []
                    if (feed.medias && feed.medias.length > 0) {
                        const mediaFiles = feed.medias.map(m => getCompressImageUrl(m, 600))
                        feed.mediaFiles = mediaFiles
                    }

                    if (feed.authorRef.isPartner) {
                        const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
                            user: feed.author
                        }))
                        feed.partnerData = partnerByUserRes.data.partnerByUser.items[0]
                    }

                    if (feed.video) {
                        const videoURL = await Storage.get(feed.video)
                        feed.videoURL = videoURL
                    }

                    if (feed.sharePostID) {
                        const sharePostRes = await API.graphql(graphqlOperation(getSharePost, {
                            id: feed.sharePostID
                        }))
                        if (sharePostRes.data.getPost && sharePostRes.data.getPost.medias.length > 0) {
                            const mediaFiles = sharePostRes.data.getPost.medias.map(m => getCompressImageUrl(m, 600))
                            sharePostRes.data.getPost.mediaFiles = mediaFiles
                        }
                        feed.sharePost = sharePostRes.data.getPost
                    }

                }
                this.posts = res.data.postsByAuthor.items
                console.log(this.posts)

                this.isLoading = false
            } catch (err) {
                console.log(err)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.vx-card .vx-card__collapsible-content.vs-con-loading__container {
    overflow: unset !important;
}

#button-load-more-posts {
    width: 200px;
    height: 80px;
    justify-content: center;
    align-items: center;
}

.post-content>p {
    white-space: pre-line;
    word-break: break-all;
}

a {
    color: #007bff !important;
}

// .og-container:hover {
//   opacity: 0.5;
// }

.og-img {
    object-fit: cover;
    width: 100%;
    max-height: 400px;
}

.og-video-player {
    height: 400px
}

.sell-nft li,
.buy-nft li {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    border-bottom: 1px solid #dae1e7;
    ;
}

.post-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
}

.gallery-item {
  width: 100%;
  height: auto;
  object-fit: cover;
}

</style>
