<template>
  <div id="profile-page">
    <vx-card class="mb-3 mb-md-5 mt-5 profile-header-card">
      <!-- PROFILE HEADER -->
      <div class="profile-header">
        <div class="relative">
                <div class="cover-container rounded-t-lg">
                    <img src="@/assets/images/profile/cover-photos/cp-cover2.png" alt="user-profile-cover" class="responsive block">
                </div>
                <div class="profile-img-container pointer-events-none">
                    <div>
                        <vs-avatar class="user-profile-img visibility-hidden" src="" size="85px" />
                    </div>
                    <div class="profile-actions pointer-events-auto flex">
                        <vs-button @click="toggleEditPopup" v-if="isEdit" icon-pack="feather" radius icon="icon-edit-2"></vs-button>
                        <vs-button @click="toggleCoverImgUpdate" v-if="isEdit" icon-pack="feather" radius icon="icon-image" class="ml-2 lg:ml-4"></vs-button>
                    </div>
                </div>
            </div>

        <div class="md:flex md:items-end md:justify-between p-6">
     
          <div class="lg:flex w-full md:w-8/12 pp-profile">
            <div>
              <div class="relative p-pi">
          <avatar style="margin-left:5px;" v-if="userData.photoURL.split('/')[userData.photoURL.split('/').length-1] === 'default_dp.png'" class="border-2 border-solid border-white mr-2 my-1" :username="userData.displayName" :size="80" />

              <vs-avatar
              v-else
                :src="userData.photoURL" 
                class="user-profile-img" size="90px" />  
                 <div v-if="isEdit" class="pp-edit-icon">
            <a class="cursor-pointer" @click="toggleProfileImageUpdate"
              ><span class="material-icons">edit</span></a
            >
          </div> 
              </div>       
            </div>
            <div class="lg:ml-4">
              <div class="md:flex items-center">
                <h4 class="font-semibold mr-5">{{ userData.displayName }}</h4>
                <div class="flex mt-2 md-mt-0 mb-2 md-mb-0">
                <feather-icon v-if="userData.name !== currentUser.name"  @click="goToChatRoom()" icon="MessageSquareIcon" class="text-primary cursor-pointer mr-2" svgClasses="w-8 h-8 "></feather-icon>
                <!-- <vs-button
                v-if="userData.name !== currentUser.name"
              color="primary"
              icon-pack="feather"
              icon="icon-message-square"
              class="mr-2"
              size="small"
              @click="goToChatRoom()"
              >Message Me</vs-button
            > -->
                <ProfileFollowButton v-if="!isEdit" :email='userData.email' :notify='userData.enablePostNotification'/>
                </div>
              </div>

              <div class="tagline-text text-lg ">
                <div class="flex items-center">
                  <p>{{ userData.studyStatus }}</p>
                  <feather-icon  v-if="userData.isUniEmailVerify" class="ml-1" icon="CheckIcon" svgClasses="h-4 w-4 bg-success rounded-full text-white"></feather-icon>
                </div>
                <div v-if="userData.headline" class="mt-3 flex items-start">
                  <feather-icon svgClasses="w-5 h-5" icon="SmileIcon" class="mr-2 mt-1" />
                  <p class="mt-0">{{ userData.headline }}</p>
                </div>
                
                <div v-if="userData.location" class="mt-3 flex items-start">
                  <feather-icon svgClasses="w-5 h-5" icon="MapPinIcon" class="mr-2 mt-1" />
                  <p>{{ userData.location }}</p>
                </div>
              </div>

            </div>
          </div>
          <div>
          <!-- <vs-button color="primary" icon="edit" @click="toggleEditPopup" v-if="isEdit"></vs-button>  -->
          <div class="social-media">
          <a v-if='userData.facebookLink!==null && userData.facebookLink!=""' @click='socialMedia(userData.facebookLink)' class="mr-2 cursor-pointer" ><img src="@/assets/images/logo/Facebook_logo.svg" alt="user-profile-cover"  /></a>
          <a v-if='userData.twitterLink!==null && userData.twitterLink!=""' @click='socialMedia(userData.twitterLink)' class="mr-2 cursor-pointer"><img src="@/assets/images/logo/Twitter_logo.png" alt="user-profile-cover"  /></a>
           <a v-if='userData.instagramLink!==null && userData.instagramLink!=""' @click='socialMedia(userData.instagramLink)' class="mr-2 cursor-pointer"><img src="@/assets/images/logo/Instagram_logo.svg" alt="user-profile-cover"  /></a>
           <a v-if='userData.linkedinLink!=null && userData.linkedinLink!=""' @click='socialMedia(userData.linkedinLink)' class="mr-2 cursor-pointer"><img src="@/assets/images/logo/Linkedin_logo.png" alt="user-profile-cover"  /></a>
           <a v-if='userData.webLink!==null && userData.webLink!==""' @click='socialMedia(userData.webLink)' class="cursor-pointer"><img src="@/assets/images/logo/www.png" alt="user-profile-cover"  /></a>
          </div>



          <!--
          <a class="twitter-icon cursor-pointer ml-2"><feather-icon icon="TwitterIcon" svgClasses="h-5 w-5 mt-5 md:mt-0" class="" /></a>
          <a class="linkedin-icon cursor-pointer ml-2"><feather-icon icon="LinkedinIcon" svgClasses="h-5 w-5 mt-5 md:mt-0" class="" /></a>
          <a class="instagram-icon cursor-pointer ml-2"><feather-icon icon="InstagramIcon" svgClasses="h-5 w-5 mt-5 md:mt-0" class="" /></a>
          <a class="web-icon cursor-pointer ml-2"><feather-icon icon="GlobeIcon" svgClasses="h-5 w-5 mt-5 md:mt-0" class="" /></a>
-->
          </div> 
        </div>
      </div>

    <!-- Headline Popup -->
    <div>
    <vs-popup title="Update profile" :active.sync="headLinePopupActive">
      <vs-input 
        class="w-full" 
        label="Headline" 
        v-model="headline" 
        :rows="2" 
        :maxlength="100"
        @input="handleInput"
      />
      <div style="margin-top: 1rem;">
        <label>Location</label>
      </div>
      <location-select ref="locSelectRef" />
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mt-4" @click="updateAboutAndHeadline">Update</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
    </vx-card>
    <ProfileImgUpdatePopup
      ref="profileImgPopup"
      @onFinishUpload="setProfilePhotoURL"
    />

    <CoverImgUpdatePopup
      ref="coverImgPopup"
      @onFinishUpload="setCoverPhotoURL"
    />

  </div>
</template>

<script>
import ProfileFollowButton from './Follow'
import LocationSelect from './LocationSelect'
import { createErrorObject } from '@/utils'
import ProfileImgUpdatePopup from './ProfileImgUpdatePopup' 
import CoverImgUpdatePopup from './CoverImgUpdatePopup'
import Avatar from 'vue-avatar'

export default {
  name: 'ProfileHeader',
  data () {
    return {
      headLinePopupActive: false,
      coverProfile: false,
      headline: '',
    }
  },
  props: ['data'],
  components: {
    ProfileFollowButton,
    LocationSelect,
    ProfileImgUpdatePopup,
    CoverImgUpdatePopup,
    Avatar
  },
  watch: {
    '$route.params.user_name' (userName) {
      this.$store.dispatch('profile/fetchProfile', userName)
    }
  },
  computed: {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.user_name
    },
    userData () {
      return this.$store.state.profile.profile
    },
    currentUser() {
      return this.$store.state.auth.userData
    }
  },
  methods: {

    handleInput(event) {
      if (event.target.value.length > 100) {
        this.headline = event.target.value.slice(0, 100);
      }
    },
    
    onImageChange (e) {
      this.updateAvatarImage = e.target.files[0]
      const fr = new FileReader()
      fr.onload = (function (previewAvatarImage) {
        return function (e) {
          previewAvatarImage.src = e.target.result
        }
      })(this.previewAvatarImage)
      fr.readAsDataURL(this.updateAvatarImage)
    },
    socialMedia(val){
           window.open(val,'_blank')
    },
    toggleProfileImageUpdate () {
      this.$refs.profileImgPopup.active = true
    },
    toggleCoverImgUpdate () {
      this.$refs.coverImgPopup.coverProfile = true
    },
    setProfilePhotoURL (url) {
      this.logoImg = url
      this.$refs.profileImgPopup.active = false
    },
    setCoverPhotoURL (url) {
      this.coverImgUrl = url
    },
    updateUserProfileImages () {
      if (this.updateAvatarImage === '' && this.updateCoverImage === '') {
        this.$vs.notify({
          title: 'Failed',
          text: 'No image selected to upload',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.$vs.loading()
        this.$store.dispatch('auth/updateUserProfileImages', {
          email: this.userData.email_id,
          updateAvatarImage: this.updateAvatarImage,
          updateCoverImage: this.updateCoverImage
        })
      }
    },
    updateAboutAndHeadline () {
      if (this.headline.length > 200) {
        this.$vs.notify(createErrorObject('Error', 'Headline field reach maximum length 200'))
      }
      this.$store.dispatch('profile/updateProfileHeader', {
        userName: this.$route.params.user_name,
        headline: this.headline,
        location: this.$refs.locSelectRef.locationInput
      })
      this.headLinePopupActive = false
    },
    toggleEditPopup () {
      this.headLinePopupActive = true
      this.headline = this.$store.state.profile.profile.headline
      this.$refs.locSelectRef.selected = true
      this.$refs.locSelectRef.locationSelect = this.$store.state.profile.profile.location 
      this.$refs.locSelectRef.locationInput = this.$store.state.profile.profile.location
    },

    goToChatRoom () {
      this.$router.push(`/chat/${this.userData.name}-${this.$store.state.auth.userData.name}`)
    },
  },
  created () {
    this.$store.dispatch('profile/fetchProfile', this.$route.params.user_name)
    this.$store.state.profile.profile
    
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.my-profile-img-container {
  display: flex;
  align-items: center;
  position: absolute;
  // bottom: -5.7rem;
  bottom: -1.4rem;
  left: 10%;
  width: 80%;
  justify-content: space-between;
}

.cover-profile-img {
  height: 450px;
  width: 100%;
  object-fit: cover;
}
.z-200 {
  z-index: 200!important;
}

.pp-profile .con-vs-avatar {
  background: transparent;
}
.p-pi:hover .pp-edit-icon {
  background-color: #dc123e;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50% !important;
  color: #fff !important;
  position: absolute;
  top: 5px;
  left: 70px;
  // display: inline-block;
  text-align: center;
  // -webkit-transition: all .1s ease-in-out;
  // -o-transition: all .1s ease-in-out;
  // transition: all .1s ease-in-out;
  // overflow: hidden;
   display: block;
}
.pp-edit-icon {
  display: none;
}
.pp-edit-icon > a {
  color: #fff !important;
}
.pp-edit-icon > a > span {
  position: relative;
  font-size: 18px;
  color: #fff !important;
  top: 50%;
  display: block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}
.visibility-hidden{
  visibility: hidden;
}
.profile-header-card .vx-card__body{
  padding: 0 !important;
}
.facebook-icon{
  background-color: rgb(66, 103, 178);
  border-radius: 6px;
  padding: .75rem;
  padding-bottom: 0.4rem;
  
}
.linkedin-icon{
  background-color: rgb(14, 118, 168);
  border-radius: 6px;
  padding: .75rem;
  padding-bottom: 0.4rem;
}
.twitter-icon{
  background-color: rgb(29, 161, 242);
  border-radius: 6px;
  padding: .75rem;
  padding-bottom: 0.4rem;
}
.instagram-icon{
  background-color: rgb(88, 81, 216);
  border-radius: 6px;
  padding: .75rem;
  padding-bottom: 0.4rem;
}
.web-icon{
  background-color: #4b4b4b;
  border-radius: 6px;
  padding: .75rem;
  padding-bottom: 0.4rem;
}
.linkedin-icon .feather, .twitter-icon .feather, .facebook-icon .feather, .instagram-icon .feather, .web-icon .feather{
  color: #fff;
}
.social-media a{
   display:inline-block;
}
.social-media img{
  width:32px;
  height:32px;
}
@media only screen and (max-width: 600px) {
  .social-media img{
  width:26px;
  height:26px;
}
.social-media{
  margin-top: 16px;

}
}
</style>